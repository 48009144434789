<template>
  <v-container>
    <!-- Header -->
    <v-row class="d-flex ma-2">
      <v-icon large left color="blue">mdi-truck</v-icon>
      <div class="text-h5 pa-4 blue--text darken-2">
        รายละเอียดนัดหมายรับเวชภัณฑ์ : {{ asnNumber }}
      </div>
    </v-row>
    <v-divider />

    <!-- DETAIL -->
    <v-row class="py-4">
      <v-col cols="4" class="pa-2">
        <v-card class="d-flex flex-row align-center">
          <v-avatar size="50" class="mx-4 elevation-1" color="blue">
            <v-icon large dark> mdi-calendar-clock </v-icon>
          </v-avatar>
          <v-card-text class="d-flex flex-column blue--text darken-2 pl-0">
            <p class="font-weight-bold mb-0">กำหนดส่งโดยประมาณ</p>
            <p class="font-weight-light mb-0">{{ asnEta }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="pa-2">
        <v-card class="d-flex flex-row align-center">
          <v-avatar size="50" class="mx-4 elevation-1" color="blue">
            <v-icon large dark> mdi-factory </v-icon>
          </v-avatar>
          <v-card-text class="d-flex flex-column blue--text darken-2 pl-0">
            <p class="font-weight-bold mb-0">SPD</p>
            <p class="font-weight-light mb-0">ไปรษณีย์ดิสทริบิวชั่น</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="pa-2">
        <v-card class="d-flex flex-row align-center">
          <v-avatar size="50" class="mx-4 elevation-1" color="blue">
            <v-icon large dark> mdi-state-machine </v-icon>
          </v-avatar>
          <v-card-text class="d-flex flex-column blue--text darken-2 pl-0">
            <p class="font-weight-bold mb-0">สถานะรายการ</p>
            <p class="font-weight-light mb-0">{{ asnStatus }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- HEADER -->
    <v-row class="d-flex justify-center align-center ma-2">
      <v-icon large left color="blue">mdi-file</v-icon>
      <div class="text-h5 pa-4 blue--text darken-2">
        รายการเวชภัณฑ์ในรอบการขนส่งนี้
        <template v-if="this.$route.params.asn.status === 1000">
          <span class="black--text"
            >(เลือกรับเวชภัณฑ์ {{ sumSelected }} รายการ จาก {{ sumList }} รายการ)</span
          >
        </template>
        <template v-else>
          <span class="black--text"
            >(ยืนยันแล้ว {{ sumList }} รายการ)</span
          >
        </template>
      </div>
      <v-spacer />
      <v-btn
        v-if="this.$route.params.asn.status === 1000"
        tile
        large
        color="blue darken-2 white--text"
        @click="asnReceive = true"
      >
        <v-icon class="pr-2">mdi-plus-thick</v-icon>
        ยืนยันรับเวชภัณฑ์
      </v-btn>
    </v-row>

    <div>
      <span>เลือกประเภทรายการที่ต้องการแสดง</span>
      <v-chip-group multiple v-model="selectedType">
        <v-chip :active-class="getChipColor('med')" :input-value="true">{{
          getChipText("med")
        }}</v-chip>
        <v-chip :active-class="getChipColor('nonmed')" :input-value="true">{{
          getChipText("nonmed")
        }}</v-chip>
        <v-chip :active-class="getChipColor('herb')" :input-value="true">{{
          getChipText("herb")
        }}</v-chip>
        <v-chip :active-class="getChipColor('other')" :input-value="true">{{
          getChipText("other")
        }}</v-chip>
      </v-chip-group>
    </div>

    <v-data-table
      v-model="selectedDrugItems"
      :items-per-page="5"
      :items="getAllList"
      :headers="itemDrugHeader"
      class="mt-4"
      show-select
      item-key="asn_id"
      :footer-props="{
        'items-per-page-text': 'จำนวนรายการที่แสดงต่อหน้า',
      }"
    >
      <template v-slot:item.genericName="{ item }">
        <v-chip small class="mr-2" :color="getChipColor(item.type)">{{
          getChipText(item.type)
        }}</v-chip
        >{{ item.genericName }}
      </template>
      <template v-slot:item.recivedQuantity="{ item }">
        <v-text-field
          v-model="item.recivedQuantity"
          :disabled="!selectedDrugItems.includes(item)"
        ></v-text-field>
      </template>
    </v-data-table>
    <v-dialog v-model="asnReceive" width="50%">
      <v-card class="">
        <v-card-title class="primary white--text">
          ยืนยันรับเวชภัณฑ์เข้าคลัง
        </v-card-title>
        <v-card-content class="ma-5">
          ยืนยันการรับเวชภัณฑ์ จำนวน {{ sumSelected }} รายการ เข้าคลังรพ.สต.
        </v-card-content>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="asnReceive = false"> ยกเลิก </v-btn>
          <v-btn color="primary" class="white--text" @click="receiveASN">
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="asnSuccess" width="50%">
      <v-card class="">
        <v-card-title class="primary white--text"
          ><v-icon icon outline color="white">mdi-check-circle-outline</v-icon
          >&nbsp;สำเร็จ</v-card-title
        >
        <v-card-content class="ma-5"
          >การรับเวชภัณฑ์ จำนวน {{ sumSelected }} รายการ เข้าคลังรพ.สต.
          สำเร็จ</v-card-content
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="white--text" @click="backtoASN">
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="asnFail" width="50%">
      <v-card class="">
        <v-card-title class="red white--text"
          ><v-icon icon outline color="white">mdi-alert-box-outline</v-icon
          >&nbsp;ไม่สำเร็จ</v-card-title
        >
        <v-card-content class="ma-5"
          >การรับเวชภัณฑ์เข้าคลังไม่สำเร็จ</v-card-content
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" class="white--text" @click="asnFail = false">
            ลองอีกครั้ง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const axios = require("axios").default;
// const url = "http://localhost:8910/api";
// const url = "http://anantasolutions.co/flagship/api"
const url = "http://164.115.22.214/api";

export default {
  data: () => {
    return {
      selectedType: [0, 1, 2, 3],
      selectedDrugItems: [],
      selectedNonDrugItems: [],
      selectedHerbItems: [],
      selectedOtherItems: [],
      tab: null,
      itemDrugHeader: [
        
        {
          text: "ชื่อสามัญ",
          value: "genericName",
        },
        {
          text: "ชื่อทางการค้า",
          value: "tpuName",
        },
        {
          text: "หมายเลขล็อต",
          value: "lotNo",
        },
        {
          text: "วันหมดอายุ",
          value: "expired",
        },
        {
          text: "จำนวนส่งมอบ",
          value: "quantity",
        },
        {
          text: "ราคาต่อหน่วย(บาท)",
          value: "price_per_unit",
        },
        {
          text: "จำนวนที่ได้รับมอบ",
          value: "recivedQuantity",
        },
        {
          text: "หน่วยนับ",
          value: "unit",
        },
      ],
      itemDrugList: [],
      itemNonDrugList: [],
      itemHerbList: [],
      itemOtherList: [],
      asnNumber: null,
      asnEta: null,
      asnStatus: null,
      asnReceive: false,
      asnSuccess: false,
      asnFail: false,
    };
  },
  methods: {
    applyRouterLink(name) {
      this.$router.push({ name });
    },
    getChipColor(type) {
      if (type == "med") return "indigo lighten-4";
      else if (type == "nonmed") return "blue lighten-3";
      else if (type == "herb") return "green lighten-3";
      else return "yellow";
    },
    getChipText(type) {
      if (type == "med") return "เวชภัณฑ์ยา";
      else if (type == "nonmed") return "เวชภัณฑ์ที่มิใช่ยา";
      else if (type == "herb") return "ยาสมุนไพร";
      else return "อื่นๆ";
    },
    initialize() {
      let asn = this.$route.params.asn;
      this.asnNumber = asn.id;
      this.asnEta = asn.eta;
      switch (asn.status) {
        case 1000:
          this.asnStatus = "อยู่ในระหว่างการจัดส่ง";
          break;
        case 1001:
          this.asnStatus = "จัดส่งสำเร็จแล้ว";
          break;
        default:
          this.asnStatus = "อยู่ระหว่างการตรวจสอบข้อมูล";
          break;
      }
      this.itemDrugList = new Array();
      this.itemNonDrugList = new Array();
      this.itemHerbList = new Array();
      this.itemOtherList = new Array();
      asn.list.forEach((el) => {
        // console.log(el);
        var item = {
            asn_id: el.asn_id,
            dosage: el.dosage,
            expired: el.expiry_date,
            id: el.trade_id,
            lotNo: el.lot_number,
            packingSize: el.lot_uom,
            quantity: el.lot_qty - el.received,
            recivedQuantity: el.lot_qty,
            tpuName: el.trade_name,
            type: el.type,
            unit: el.lot_uom,
            genericName: el.generic_name,
            price_per_unit: el.price_per_unit,
            mfg_date: el.mfg_date,
            barcode: el.barcode,
        };

        if(asn.status == "1000"){
          if(el.lot_qty > 0 && el.received != el.lot_qty){
            if (el.type == "med") this.itemDrugList.push(item);
            else if (el.type == "nonmed") this.itemNonDrugList.push(item);
            else if (el.type == "herb") this.itemHerbList.push(item);
            else this.itemOtherList.push(item);
          }
        }
        else if (asn.status == "1001") {
          item["quantity"] = el.lot_qty;
          item["recivedQuantity"] = el.received;
          if (el.type == "med") this.itemDrugList.push(item);
          else if (el.type == "nonmed") this.itemNonDrugList.push(item);
          else if (el.type == "herb") this.itemHerbList.push(item);
          else this.itemOtherList.push(item);
        }
      
        
        
      });
    },
    receiveASN() {
      // console.log(this.selectedDrugItems);
      if (this.selectedDrugItems.length > 0) {
        axios
          .post(url + "/pcoc/receive_asn", {
            org_id: this.$cookies.get("user").hosp_code,
            receive_asns: this.selectedDrugItems,
          })
          .then((res) => {
            // console.log(res.data);
            if (res.data.status == "success") {
              this.asnReceive = false;
              this.asnSuccess = true;
            } else {
              this.asnReceive = false;
              this.asnFail = true;
            }
          })
          .catch((err) => {
            console.log(err.data);
          });
      }
    },
    fetchASN() {
      // console.log('fetchASN')
      axios
        .post(url + "/pcoc/get_asn", {
          hosp_code: this.$cookies.get("user").hosp_code,
        })
        .then((response) => {
          // console.log(response.data);
          this.asnTable.items.ongoing = new Array();
          this.asnTable.items.completed = new Array();
          response.data.asns.forEach((asn) => {
            var item = {
              date: new Date(asn.asn_date).toLocaleString("th-TH"),
              id: asn.asn_number,
              spd: "ไปรษณีย์ดิสทริบิวชั่น",
              eta: new Date(asn.shipped_date).toLocaleString("th-TH"),
              status: asn.status,
              list: asn.items,
            };
            if (asn.status == 1001 || asn.status == 1002)
              this.asnTable.items.completed.push(item);
            else this.asnTable.items.ongoing.push(item);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    backtoASN() {
      (this.asnSuccess = false), this.applyRouterLink(this.$routes.ASN.name);
    },
  },
  computed: {
    sumSelected() {
      return (
        this.selectedDrugItems.length +
        this.selectedNonDrugItems.length +
        this.selectedHerbItems.length +
        this.selectedOtherItems.length
      );
    },
    sumList() {
      return (
        this.itemDrugList.length +
        this.itemNonDrugList.length +
        this.itemHerbList.length +
        this.itemOtherList.length
      );
    },
    getAllList() {
      var allitemList = [];
      if (this.selectedType.includes(0)) {
        allitemList = allitemList.concat(this.itemDrugList);
      }
      if (this.selectedType.includes(1)) {
        allitemList = allitemList.concat(this.itemNonDrugList);
      }
      if (this.selectedType.includes(2)) {
        allitemList = allitemList.concat(this.itemHerbList);
      }
      if (this.selectedType.includes(3)) {
        allitemList = allitemList.concat(this.itemOtherList);
      }
      return allitemList;
    },
  },
  mounted() {
    this.initialize();
  },
  // watch: {
  //   selectedDrugItems :{
  //     handler(newVal, oldVal){
  //       console.log("Old value:" , oldVal)
  //       console.log("New Value:" , newVal)
  //     }
  //   }
  // }
};
</script>